import HorizontalLayout from "../@core/layouts/HorizontalLayout"
// import NavbarComponent from '../views/components/navComponent'
import NavbarComponent from '../views/components/navComponent'
import VerticalLayout from "../@core/layouts/VerticalLayout"

import React, { useDispatch } from 'react'
import { Route } from "react-router"
import { BrowserRouter } from "react-router-dom"
import {
    Breadcrumb,
    BreadcrumbItem,
    UncontrolledButtonDropdown,
    DropdownMenu,
    DropdownItem,
    DropdownToggle
} from 'reactstrap'
export default function index() {
    return (
        <VerticalLayout>

            <BrowserRouter>

                <NavbarComponent />
            </BrowserRouter>
        </VerticalLayout>
    )
}
