// ** Auth Endpoints

// const api_url = process.env.SERVER_URL
const api_url = 'http://158.255.74.242'
export default {
  // loginEndpoint:`${api_url}/auth/login`,

  loginEndpoint:`${api_url}/auth/login`,
  registerEndpoint: '/jwt/register',
  refreshEndpoint: '/jwt/refresh-token',
  logoutEndpoint: '/jwt/logout',

  // ** This will be prefixed in authorization header with token
  // ? e.g. Authorization: Bearer <token>
  tokenType: 'Bearer',

  // ** Value of this property will be used as key to store JWT token in storage
  storageTokenKeyName: 'accessToken',
  storageRefreshTokenKeyName: 'refreshToken'
}
