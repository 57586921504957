// ** Icons Import
import { Heart } from 'react-feather'
import { BiGitBranch } from "react-icons/bi"
const Footer = () => {
  return (
    <p className='clearfix mb-0'>
      <span className='float-md-left d-block d-md-inline-block mt-25'>
        COPYRIGHT © {new Date().getFullYear()}{' '}
        <a href='https://1.envato.market/pixinvent_portfolio' target='_blank' rel='noopener noreferrer'>
          favin
        </a>
        <span className='d-none d-sm-inline-block'>, All rights Reserved</span>
      </span>
      <span className='float-md-right d-none d-md-block'>
        Hand-crafted v1 & Made with
        <BiGitBranch color='rgb(115 103 240 / 70%)' />
        {/* <Heart size={14} /> */}
      </span>
    </p>
  )
}

export default Footer
